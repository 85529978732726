.ComboChart {
  margin-left: 1rem;
  margin-right: 1rem;
  text-align: center;
}

// Mobile first
@media only screen and (max-width: 767px) {
  .ComboChart {
    margin-left: inherit;
    margin-right: inherit;
  }
}

// Analog tooltip fix bug
.goog-tooltip {
  z-index: 1;
  div {
    background-color: white !important;
    padding: 0 8px !important;
  }
}

// Digital tooltip fix bug <https://stoerk.atlassian.net/browse/WEB-609>
.tooltip-timeline-chart {
  color: black;
}
