body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: overlay;
}

/* TESTING */
/* html,
body {
  overflow: hidden;
} */

html,
body,#root {
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.d-flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.d-flex-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

html {
  scrollbar-width: 10px;
  scrollbar-color: #ff3b4e #444444;
  scrollbar-gutter: stable;
}
::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
  -webkit-appearance: auto !important;
}
.nice-scroll-light::-webkit-scrollbar-track {
  background: #ededee;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  background: #444444;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #ff6e46;
  background-image: linear-gradient(135deg, #ff6e46 0%, #ff3b4e 100%);
  border-radius: 10px;
}

html {
  scrollbar-width: 10px;
  scrollbar-color: #ff3b4e #444444;
  scrollbar-gutter: stable;
}

.nice-scroll-light::-webkit-scrollbar-track {
  background: #ededee;
  border-radius: 10px;
}

#root *::-webkit-scrollbar {
  width: 10px !important;
  -webkit-appearance: auto !important;
}
#root *::-webkit-scrollbar-track {
  background: #8884;
  border-radius: 10px;
}
#root *::-webkit-scrollbar-thumb {
  background: #ff6e46;
  background-image: linear-gradient(135deg, #ff6e46 0%, #ff3b4e 100%);
  border-radius: 10px;
}

#root *::-webkit-scrollbar-thumb:window-inactive {
  background: #ff6e46;
  background-image: linear-gradient(135deg, #ff6e46 0%, #ff3b4e 100%);
  border-radius: 10px;
}

#root *::-webkit-scrollbar-thumb:hover {
  background: #ff6e46;
  background-image: linear-gradient(135deg, #ff977a 0%, #ff707e 100%);
  border-radius: 10px;
}

/* iOS fix disabled */
input:disabled {
  opacity: 1;
}
